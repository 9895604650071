import { useRef } from "react";
import { Icon } from "@/assets";

export function UploadFile({ handleFileChange, itemDesc, dashed }) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const DASHED_BORDER = { border: "1px dashed #B6CADE" }

  return (
    <div
      className="flex flex-col gap-2 items-center border rounded-12 p-10"
      style={dashed ? DASHED_BORDER : {}}

    >
      <button
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <Icon name="Cloud" />
      </button>

      <p className="text-18 leading-6 text-black/90 text-center p-2">
        <button
          onClick={() => {
            fileInputRef.current?.click();
          }}
          className="text-blue-200 underline"
        >
          Click to upload
        </button>
        {" "}
        or drag and drop {itemDesc ?? "your documents"}
      </p>

      <p className="text-14 leading-6 text-black/60">Maximum size: 100 MB</p>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
        multiple
      />
      {/* TODO: to upload a directory: */}
      {/*<input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} directory="" allowdirs="" webkitdirectory="" />*/}
    </div>
  );
}