import { AllProjects } from "@/components";

export default function page() {
  return (
    <>
      <div className="w-full bg-border-gray" style={{ height: "1px" }}></div>
      <div className="py-8 flex flex-col gap-12 wrapper">
        <AllProjects />
      </div>
    </>
  );
}
