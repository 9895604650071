import axios from 'axios'
import { createAsyncThunk } from "@reduxjs/toolkit"
import { API_URL } from "@/config"
import { AppDispatch, RootState } from "@/redux/store";

interface LoginData {
  email: string
  password: string
}

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (loginData: LoginData, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams()
      params.append("username", loginData.email)
      params.append("password", loginData.password)
      const { data } = await axios.post(`${API_URL}/auth/login`, params)
      return data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
