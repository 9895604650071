import { Icon } from "@/assets";
import {Link} from "react-router-dom";
import ProgressBar from "./progressBar";
import FolderDropdown from "@/components/folders/FolderDropdown";
import { DirectorySchema } from "@/api/resources";

interface FolderProps {
  link: string;
  folder: DirectorySchema
}

export default function Folder({
  folder,
  link,
}: FolderProps) {
  const progress = !folder.total_documents ? 0: (folder.completed_documents / folder.total_documents) * 100
  return (
    <div className="flex flex-col gap-2 justify-between rounded-14 p-6 pr-4 w-full border border-border-gray">
      <div className="flex justify-between items-center">
        <Icon name="Folder" />
        <FolderDropdown folder={folder} />
      </div>
      <div>
        <Link className="text-16 font-500 text-black" to={link}>
          {folder.display_name}
        </Link>
        {folder.completed_documents && folder.completed_documents === folder.total_documents ? (
          <p className="text-text-green">Done</p>
        ) : (
          <p className="font-500 text-14 leading-6 text-gray-400">
            <span className="text-black">{folder.completed_documents ?? 0}</span> of{' '}
            <span>{folder.total_documents ?? 0}</span> files done
          </p>
        )}
        <ProgressBar
          percentage={progress}
        />
      </div>
    </div>
  );
}
