import RecentProjects from "./recentProjects"
import Welcome from "./welcome"

export default function Header() {
  return (
    <div className="bg-bgSecondary w-full flex justify-center">
      <div className="flex flex-col gap-6 py-10 wrapper">
        <Welcome />
        <RecentProjects />
      </div>
    </div>
  )
}
