import { Icon } from "@/assets"
import Documents from "@/components/Documents"
import Folder from "@/components/folders"
import { useMemo, useState } from "react"
import { CreateOrRenameFolderDialog } from "@/components/Project/project/CreateOrRenameFolderDialog"
import { ResourceSchema } from "@/api/resources"
import { Button } from "@/components/ui/button"
import { useProjectContext } from "@/components/Project/ProjectContext"
import EmptyProject from "@/components/Project/project/emptyProject"
import FilesHeader from "@/components/Project/project/FilesHeader";

interface KeywordsDataProps {
  data: ResourceSchema[]
  // if we're not in the root folder (used as param for creating and uploading)
  folderId?: string
  isSearchingKeywords: boolean
}

export default function FilesystemView({
  data,
  folderId,
  isSearchingKeywords,
  handleFileChange,
}: KeywordsDataProps) {
  const [isCreateFolderModal, setCreateFolderModal] = useState(false)
  const project = useProjectContext()

  const documents = useMemo(
    () => data?.filter((x) => x.resource_type === "document") ?? [],
    [data],
  )
  const folders = useMemo(
    () => data?.filter((x) => x.resource_type === "directory") ?? [],
    [data],
  )

  return (
    <>
      <div
        className={`${isSearchingKeywords ? "hidden" : "flex flex-col gap-4 pt-6"}`}
      >
        <FilesHeader title="Folders" buttons={<Button
          variant="ghost"
          className="flex gap-1.5"
          onClick={() => {
            setCreateFolderModal(true)
          }}
        >
          <Icon name="CreateFolder" />
          <span className="text-text-neutral text-16 font-500">
              Create Folder
            </span>
        </Button>} />

        <div className="grid gap-4 grid-cols-4">
          {folders.map((folder, idx) => (
            <Folder
              key={idx}
              link={`/folders/${folder.directory_id}`}
              folder={{ doneFiles: 1, totalFiles: 2, ...folder }}
            />
          ))}
        </div>
      </div>
      {project && (
        <CreateOrRenameFolderDialog
          projectId={project.project_id}
          parentId={folderId}
          action="create"
          open={isCreateFolderModal}
          onOpenChange={setCreateFolderModal}
        />
      )}

      <div className="flex flex-col gap-4 pt-4">
        {isSearchingKeywords ? (
          <Documents documents={documents} matches={documents?.length ?? 0} />
        ) : documents?.length > 0 ? (
          <Documents title={"Files"} documents={documents} />
        ) : (
          <EmptyProject handleFileChange={handleFileChange} folderId={folderId} />
        )}
      </div>
    </>
  )
}
