import { useEffect, useState } from "react"

import { Icon } from "@/assets"
import { Status } from "@/elements"
import { DropdownValue } from "@/elements/status"
import { STATUS_DROPDOWN_VALUES, StatusEnum } from "@/types/status";
import { Button } from "@/components/ui/button"
import { useDeleteProjectMutation } from "@/api/resources";
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog";
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog";
import { ProjectSchema } from "@/api/resources/types";

interface TopProps {
  project: ProjectSchema
  toggleDropdown: () => void
  toggleShare: () => void
}

// const Divider = () => (
//   <div className="h-4" style={{ borderLeft: "1px solid #B6CADE" }}></div>
// )

const Divider = () => <></>

export default function ProjectHeader({ project, toggleDropdown, toggleShare }: TopProps) {
  const initialStatus = project.status ?? StatusEnum.untouchable
  const [status, setStatus] = useState<DropdownValue>(
    STATUS_DROPDOWN_VALUES.find((x) => x.name === initialStatus),
  )

  const [isRenameModal, setRenameModal] = useState<boolean>(false)
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false)

  return (
    <div className="bg-bgSecondary w-full flex justify-center">
      <div className="flex justify-between py-8 wrapper">
        <div className="flex items-center gap-2 text-24 text-black font-500">
          <Icon name="Case" />
          {project.display_name}
        </div>

        <div className="flex items-center text-14 leading-6">
          <div className="px-3.5 font-500 text-15 leading-5 text-gray-500 flex gap-1.5 justify-between items-center">
            <span className="mr-1">Status:</span>
            <Status
              values={STATUS_DROPDOWN_VALUES}
              selectedValue={status}
              setSelectedValue={setStatus}
              border
              className="leading-4 w-24"
            />
          </div>
          <Divider />
          <Button
            variant="ghost"
            className="text-blue-200 gap-1.5"
            onClick={toggleDropdown}
          >
            <Icon name="Export" />
            Upload Document
          </Button>
          <Divider />
          <Button
            variant="ghost"
            className="gap-1.5"
            onClick={toggleShare}
            style={{ color: "#4975A1" }}
            // FIXME: replace this color everywhere !!!
          >
            <Icon name="Share" className="h-[20px]" />
            Share
          </Button>
          <Divider />
          <Button
            variant="ghost"
            className="gap-1.5"
            style={{ color: "#4975A1" }}
            onClick={() => {setRenameModal(true)}}
          >
            <Icon name="Edit" className="h-[20px]" />
            Rename
          </Button>
          <Divider />
          <Button variant="ghost" className="gap-1.5 text-background-lightRed" disabled={isDeleteModal} onClick={() => {setDeleteModal(true)}}>
            <Icon name="Delete" className="h-[20px]" />
            Delete
          </Button>
        </div>
      </div>
      <CreateOrRenameProjectDialog projectId={project.project_id} initialName={project.display_name} action="rename" open={isRenameModal} onOpenChange={setRenameModal} />
      <DeleteProjectDialog projectId={project.project_id} open={isDeleteModal} onOpenChange={setDeleteModal} />
    </div>
  )
}
