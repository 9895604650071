"use client";
import { useState } from "react";

import { Icon, Profile } from "@/assets";
import { Modal } from "@/elements";
import ProfileModal from "./profileModal";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { logout } from "@/redux/slices/auth";
import { useAppDispatch } from "@/redux/hooks";


export default function ProfileSettings() {
  const dispatch = useAppDispatch()

  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [openProfileSettings, setOpenProfileSettings] = useState<boolean>(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const doLogout = () => {
    // TODO: ?! something's not set up right
    // @ts-ignore
    dispatch(logout())
  }

  return (
    <>
      {/*<Modal isOpen={openProfileSettings} onClose={handleProfileSettings}>*/}
      {/*  <ProfileModal onClose={handleProfileSettings} />*/}
      {/*</Modal>*/}
      <ProfileModal isOpen={openProfileSettings} setOpen={setOpenProfileSettings} />
      <div className="relative">
        <DropdownMenu open={isDropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <button
              className="w-10 min-w-10 h-10 rounded-50 border border-blue-200 flex justify-center items-center"
            >
              <img src={Profile} alt={"profile"} />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-40 py-2" align="end">
            <DropdownMenuItem className="py-3" onClick={() => setOpenProfileSettings(true)}
            >
              <Icon name="IProfile" className="mr-2" /> Profile Settings
            </DropdownMenuItem>
            <DropdownMenuItem className="py-3" onClick={doLogout}
            >
              <Icon name="Logout" className="mr-2" /> Log Out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
}
