import React from "react"
import ReactDOM from "react-dom/client"
import { Provider as ReduxProvider } from "react-redux"
import { Navigate } from "react-router"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import "./index.css"

import AppPage from "@/AppPage"
import MyLibrary from "@/app/my-library/page"
import FolderPage from "@/app/projects/[project]/[folder]/page"
import ProjectPage from "@/app/projects/[project]/page"
import Projects from "@/app/projects/page"
import Login from "@/app/auth/login"
import { Dashboard } from "@/components"
import { ViewProvider } from "@/context/ViewContext"
import { store } from "@/redux/store"
import Document from "./app/document/page"
import { UploadProvider } from "@/UploadContext"
import { Toaster } from "@/components/ui/sonner"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <AppPage>
        <Dashboard />
      </AppPage>
    ),
  },
  {
    path: "/projects",
    element: (
      <AppPage>
        <Projects />
      </AppPage>
    ),
  },
  {
    path: "/document",
    element: <Document />,
  },
  {
    path: "/document/:id",
    element: <Document />,
  },
  {
    path: "/saved-searches",
    element: (
      <AppPage>
        <MyLibrary />
      </AppPage>
    ),
  },
  {
    path: "/projects/:id",
    element: (
      <AppPage>
        <ProjectPage />
      </AppPage>
    ), // Ensure ProjectPage is wrapped in AppPage if needed
  },
  {
    path: "/folders/:id",
    element: (
      <AppPage>
        <FolderPage />
      </AppPage>
    ), // Ensure ProjectPage is wrapped in AppPage if needed
  },
])

const theme = {}

async function enableMocking() {
  if (process.env.NODE_ENV !== "development" || true) {
    return
  }

  const { worker } = await import("./mock/browser.js")

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const root = ReactDOM.createRoot(document.getElementById("root")!)
enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <ViewProvider> {/* TODO: replace this */}
            <UploadProvider>
              <RouterProvider router={router} />
            </UploadProvider>
            <Toaster />
          </ViewProvider>
        </ReduxProvider>
      </ThemeProvider>
    </React.StrictMode>,
  )
})
