import { useState } from "react";
import { useDocumentContext } from "@/components/left/DocumentContext";

const matches = [
  {
    percentage: 90,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 90,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 60,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 60,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 25,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 25,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 10,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
];

const ProgressBoard = ({ percentage }: { percentage: number }) => {
  const progress = (idx: number): string => {
    const full = Math.floor((percentage / 100) * 5);

    if (idx >= full + 1) return `${backgroundColor(percentage)} opacity-40`;
    return `${backgroundColor(percentage)}`;
  };

  return (
    <div className="flex gap-[3px]">
      {[1, 2, 3, 4, 5].map((idx) => (
        <div
          key={`x-${idx}`}
          className={`w-[23px] h-1 rounded-[1px] ${progress(idx)}`}
        ></div>
      ))}
    </div>
  );
};

const probabilityBackground = (percentage: number) => {
  if (percentage > 60) return "bg-background-green";
  if (percentage > 25) return "bg-orange-300";

  return "bg-background-red";
};

const backgroundColor = (percentage: number) => {
  if (percentage > 60) return "bg-text-green";
  if (percentage > 25) return "bg-orange-400";

  return "bg-background-lightRed";
};

const textColor = (percentage: number) => {
  if (percentage > 60) return "text-text-green";
  if (percentage > 25) return "text-orange-400";

  return "text-background-lightRed";
};

const handleActiveMatchBg = (
  percentage: number,
  activeMatch: number | null,
  idx: number
): string => {
  if (activeMatch !== null) {
    if (activeMatch === idx) {
      if (percentage > 60) return "bg-text-lightGreen";
      if (percentage > 25) return "bg-orange-300";
      return "bg-background-red";
    }
    if (percentage > 60) return "bg-background-green opacity-60";
    if (percentage > 25) return "bg-orange-300 opacity-60";
    return "bg-background-red opacity-60";
  }

  return probabilityBackground(percentage);
};

const selectAnimateAndScroll = (element) => {
  element.scrollIntoView({ behavior: "smooth" })
  element.animate([
    {transform: 'scale(1.0)'},
    {transform: 'scale(1.05)'},
    {transform: 'scale(1.0)'}
  ], {
    duration: 400,
    easing: 'ease-in-out'
  })
}

export default function CriteriaMatch() {
  const { semanticMatches, activeMatch, setActiveMatch } = useDocumentContext()

  return (
    <div className={`pt-6 flex flex-col gap-4`}>
      <>
        <div>
          <p className="font-400 text-16 leading-6 text-gray-400">
            <span className="text-black">{semanticMatches.length}</span> matches found
          </p>
        </div>

        {semanticMatches.map((match, idx) => (
          <div
            key={idx}
            className={`flex flex-col gap-4 rounded-9 p-8 text-gray font-400 text-16 leading-[20.16px] text-ellipsis whitespace-nowrap overflow-hidden ${handleActiveMatchBg(
              match.value * 100,
              activeMatch,
              idx,
            )}`}
            onClick={() => {
              setActiveMatch(match.index)
              const element = document.getElementsByClassName(`semantic-match-${match.index}`)[0]
              selectAnimateAndScroll(element)
            }}
          >
            <div className="flex justify-between items-center">
              <div className="text-gray font-500 text-14 leading-[17.64px] flex gap-1 items-center">
                <span className={`${textColor(match.value * 100)}`}>
                  {(match.value * 100).toFixed(2)}%
                </span>
                Probability
              </div>
              <ProgressBoard percentage={match.value * 100} />
            </div>
            <div className="text-gray text-16 leading-4 overflow-hidden whitespace-nowrap text-ellipsis font-400">
              {match.text}
            </div>
          </div>
        ))}
      </>
    </div>
  );
}
