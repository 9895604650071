import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { AccessLevels } from "@/components/Project/project/ProjectHeader/shareModal/types"

interface Props {
  defaultValue: AccessLevels
  onValueChange: (value: AccessLevels) => void
}

const options = [AccessLevels.Editor, AccessLevels.Owner, AccessLevels.Viewer]

export default function AccessDropdown({ defaultValue, onValueChange }: Props) {
  return (
    <Select defaultValue={defaultValue} onValueChange={onValueChange}>
      <SelectTrigger className="border-none shadow-none h-0 w-[100px]">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem value={option}>{option}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
