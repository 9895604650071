import React, { createContext, useState } from "react";

import { ProjectSchema } from "@/api/resources/types"
import { useUploadFile } from "@/api/upload_file";
import { useUploadContext } from "@/UploadContext";

const ProjectContext = createContext<ProjectSchema|null>(null)

export const useProjectContext = () => {
  const ctx = React.useContext(ProjectContext)
  // if (!ctx) throw new Error("Project can't be null in useProjectContext")
  return ctx
}

export const useOnUploadFileChange = (folderId?: string) => {
  const project = useProjectContext()
  const fileUpload = useUploadFile()
  // TODO: can we actually make this global? that would be fn cool
  const { chosenFiles, setChosenFiles } = useUploadContext()

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && project?.project_id != null && fileUpload != null) {
      const filesToUpload: {file: File, formData: FormData}[] = []
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        // We need to upload them *one by one* !!!
        const formData = new FormData()
        formData.append("document", file)
        if (folderId) {
          formData.append("directory_id", folderId)
        }
        // 1) Go over all new files to upload and add them to the list
        filesToUpload.push({file, formData})
      }
      let fileStatusItems = filesToUpload.map(({file}) => ({file, "status": "pending", "progress": 0}))
      setChosenFiles(fileStatusItems as any)
      // 2) Upload them one at a time (with await), sending progress from callback
      for (let item of filesToUpload) {
        const {file, formData} = item
        try {
          await fileUpload.uploadFn(project.project_id, formData, (event) => {
            console.log(event)
            // TODO: also include size / time estimate?
            const progress = event.progress
            fileStatusItems = fileStatusItems.map((x) => x.file != file ? x : {...x, progress, status: "in_progress"})
            setChosenFiles(fileStatusItems as any)
          })
          // 3) Set the file as done / errored after await returns (try-catch)
          fileStatusItems = fileStatusItems.map((x) => x.file != file ? x : {...x, progress: 100, status: "done"})
          setChosenFiles(fileStatusItems as any)
        } catch (e) {
          fileStatusItems = fileStatusItems.map((x) => x.file != file ? x : {...x, status: "error"})
          setChosenFiles(fileStatusItems as any)
        }
      }
    }
  };
  return { handleFileChange, fileUpload, chosenFiles, setChosenFiles }
}

export default ProjectContext
