import { FC, SVGProps } from "react";

import { IconNameEnum } from "@/types/icons";
import BackArrow from "./icons/BackArrow.svg?react";
import BlackArrow from "./icons/Blackarrow.svg?react";
import BlueDownarrow from "./icons/BlueDownarrow.svg?react";
import Bookmark from "./icons/Bookmark.svg?react";
import Camera from "./icons/camera.svg?react";
import Case from "./icons/Case.svg?react";
import Chevron from "./icons/Chevron.svg?react";
import Close from "./icons/Close.svg?react";
import Close24 from "./icons/Close24.svg?react";
import Cloud from "./icons/Cloud.svg?react";
import Copy from "./icons/Copy.svg?react";
import CreateFolder from "./icons/Create Folder.svg?react";
import Dashboard from "./icons/Dashboard.svg?react";
import Delete from "./icons/Delete.svg?react";
import DocumentSm from "./icons/Document-sm.svg?react";
import Document from "./icons/Document.svg?react";
import Downarrow from "./icons/DownArrow.svg?react";
import Edit from "./icons/Edit.svg?react";
import Export from "./icons/Export.svg?react";
import Folder from "./icons/Folder.svg?react";
import Library from "./icons/Library.svg?react";
import LibraryBlue from "./icons/LibraryBlue.svg?react";
import LibraryBlueBig from "./icons/LibraryBlueBig.svg?react";
import Link from "./icons/Link.svg?react";
import Logout from "./icons/Logout.svg?react";
import More1 from "./icons/More-1.svg?react";
import More from "./icons/More.svg?react";
import OrangeDownArrow from "./icons/OrangeDownArrow.svg?react";
import Plus from "./icons/Plus.svg?react";
import IProfile from "./icons/Profile.svg?react";
import Projects from "./icons/Projects.svg?react";
import Search24 from "./icons/Search-24.svg?react";
import Search from "./icons/Search.svg?react";
import Share from "./icons/Share.svg?react";
import SingleView from "./icons/SingleView.svg?react";
import Sort from "./icons/Sort.svg?react";
import SplitVector from "./icons/SplitVector.svg?react";
import SplitView from "./icons/SplitView.svg?react";
import Tick from "./icons/Tick.svg?react";
import UploadPhoto from "./icons/Upload Photo.svg?react";

const icons: {
  [key in IconNameEnum]: FC<SVGProps<SVGSVGElement>>;
} = {
  BackArrow,
  BlueDownarrow,
  Bookmark,
  Case,
  Chevron,
  Close,
  Close24,
  CreateFolder,
  Dashboard,
  Delete,
  Document,
  DocumentSm,
  Downarrow,
  Edit,
  Export,
  Folder,
  Library,
  LibraryBlue,
  LibraryBlueBig,
  More,
  More1,
  Plus,
  Projects,
  Search,
  Search24,
  Share,
  UploadPhoto,
  OrangeDownArrow,
  SplitView,
  SingleView,
  SplitVector,
  Sort,
  IProfile,
  Logout,
  Camera,
  Tick,
  Cloud,
  Link,
  BlackArrow,
  Copy,
};

type IconProps = {
  name: keyof typeof IconNameEnum;
  color?: string;
  strokeColor?: string;
  className?: string;
};

export function Icon({ name, color, className, strokeColor }: IconProps) {
  const Ico = icons[name];

  return (
    <Ico
      style={{ fill: color, contentVisibility: "auto", stroke: strokeColor }}
      className={className}
    />
  );
}

import Guy from "./images/Guy.png";
import Jacob from "./images/Jacob.png";
import Logo from "./images/Pactus AI.png";
import Profile from "./images/Profile.png";
import UserAvatar from "./images/User Avatar.png";
import Wade from "./images/Wade.png";

export { Guy, Jacob, Logo, Profile, UserAvatar, Wade };
