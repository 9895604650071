import { DropdownValue } from "@/elements/status";

export enum StatusEnum {
  done = "Done",
  untouchable = "Not Started",
  inProgress = "In Progress",
  inReview = "In Review",
}

export const STATUS_DROPDOWN_VALUES: DropdownValue[] = [
  {"name": "Not Started", "id": "not_started", "color": "#7c9fc3"},
  {"name": "In Progress", "id": "in_progress", "color": "#3076ee"},
  {"name": "In Review", "id": "in_review", "color": "#d3ae27"},
  {"name": "Done", "id": "done", "color": "#36c13b"},
]
