import React from "react"

import { AppHeader } from "@/components"
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { Navigate } from "react-router";
import { useGetUserQuery } from "@/api/resources";
import { logout } from "@/redux/slices/auth";

export default function AppPage({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(state => state.auth.accessToken)
  const { data, isLoading, isError, error } = useGetUserQuery()

  if (accessToken === null) {
    return <Navigate to="/login" />
  }

  // TODO: display a loading screen when isLoading and avoid showing the UI?
  // or maybe when accessToken != null, then do show the UI, but child components will show their own placeholders
  if (isError && error?.status == 401) {
    // access token expired
    console.log("access token expired")
    dispatch(logout())
    return <Navigate to="/login" />
  }

  return (
    <div className="w-full h-full flex flex-col items-center">
      <AppHeader />
      {children}
    </div>
  )
}
