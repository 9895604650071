import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loginThunk } from "@/redux/auth";

export interface AuthState {
  // username: string,
  // email: string,
  // avatarUrl: string,
  // firstName: string,
  // lastName: string
  email: string|null,
  accessToken: string|null,
}

// TODO: initialize email+token from JWT
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState: AuthState = {
  // username: "mzilinec",
  // email: "zilinec.m@gmail.com",
  // avatarUrl: "https://placehold.it/300x300",
  // firstName: "Matus",
  // lastName: "Zilinec"
  email: null,
  accessToken: userToken,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken");
      state.accessToken = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      const accessToken = action.payload.access_token
      state.accessToken = accessToken
      localStorage.setItem('userToken', accessToken)
    })
  }
})

export const { logout } = authSlice.actions
export default authSlice.reducer
