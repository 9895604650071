import { useCallback, useEffect, useRef, useState } from "react"
import { type LexicalEditor, type EditorState } from "lexical"

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"

import SaveStatusButton, { type SaveStatus } from "./SaveStatusButton"

function useInterval(callback: Function, delay: number) {
  const callbackRef = useRef<Function>()
  const cleanupRef = useRef<Function>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => {
        if (callbackRef.current) {
          cleanupRef.current = callbackRef.current()
        }
      }, delay)

      return () => {
        clearInterval(id)
        if (cleanupRef.current) {
          cleanupRef.current()
        }
      }
    }
  }, [delay])
}

export default function SavePlugin({
  autoSaveDelay = 10,
  onSave,
  onPopulate,
  onCleanup,
}: {
  autoSaveDelay?: number
  onSave?: (prevState: string, currentState: string) => void | Promise<void>
  onPopulate?: (editor: LexicalEditor) => boolean | Promise<boolean>
  onCleanup?: () => void
}) {
  const [editor] = useLexicalComposerContext()
  const [savingStatus, setSavingStatus] = useState<SaveStatus>("unchanged")
  const [editorState, setEditorState] = useState<string>()
  const [prevEditorState, setPrevEditorState] = useState<string>()

  useEffect(() => {
    async function fetchEditorState() {
      if (!onPopulate) return

      try {
        const isCached = await onPopulate(editor)
        const serializedState = JSON.stringify(editor.getEditorState().toJSON())

        setEditorState(serializedState)

        if (isCached) {
          setPrevEditorState(serializedState)
          setSavingStatus("unchanged")
        }
      } catch (error) {
        console.error("Error fetching Editor State", error)
      }
    }

    fetchEditorState()
  }, [])

  const saveState = useCallback(async () => {
    if (!onSave) return

    if (editorState === prevEditorState) {
      console.log("No changes")
      return
    }

    console.log("Saving Editor State", editorState)
    setSavingStatus("pending")
    const editorStateSnapshot = editorState

    try {
      await onSave(prevEditorState || "", editorStateSnapshot || "")

      console.log("Saved Editor State")
      setSavingStatus("success")
    } catch (error) {
      console.error("Error saving Editor State", error)
      setSavingStatus("failed")
    } finally {
      setPrevEditorState(editorStateSnapshot)
    }
  }, [setSavingStatus, editorState, prevEditorState])

  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      const jsonState = JSON.stringify(editorState.toJSON())
      setEditorState(() => jsonState)

      setSavingStatus(() =>
        prevEditorState === jsonState ? "unchanged" : "unsaved",
      )
    },
    [setEditorState, prevEditorState, setSavingStatus],
  )

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        saveState()
      }
    }

    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement,
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener("keydown", onKeyDown)
        }
        if (rootElement !== null) {
          rootElement.addEventListener("keydown", onKeyDown)
        }
      },
    )
  }, [editor, saveState])

  useInterval(() => {
    saveState()

    return () => {
      if (onCleanup) {
        onCleanup()
      }
    }
  }, autoSaveDelay * 1000)

  return (
    <>
      <SaveStatusButton
        status={savingStatus}
        disabled={
          savingStatus === "unchanged" ||
          savingStatus === "pending" ||
          savingStatus === "success"
        }
        onClick={saveState}
      />
      <OnChangePlugin onChange={onEditorChange} />
    </>
  )
}
