import Documents from "@/components/Documents";
import { recentProjects } from "@/mock/data/projects";

export default function RecentDocuments() {
  const docs = recentProjects[0].contents.filter((x) => x.resource_type === "document")
  return (
    <Documents
      title={"Recent Documents"}
      documents={docs}
    />
  );
}
