import { useState } from "react";

const matches = [
  {
    percentage: 90,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 90,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 90,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 60,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 60,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 25,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 25,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
  {
    percentage: 10,
    text: "The Discloser Keyword intends to disclose information (the Confidential Info o disclose information (the Confidential Info",
  },
];

const probabilityBackground = (percentage: number) => {
  if (percentage > 60) return "bg-background-green";
  if (percentage > 25) return "bg-orange-300";

  return "bg-background-red";
};

const textColor = (percentage: number) => {
  if (percentage > 60) return "text-text-green";
  if (percentage > 25) return "text-orange-400";

  return "text-background-lightRed";
};

const handleActiveMatchBg = (
  percentage: number,
  activeMatch: number | null,
  idx: number
): string => {
  if (activeMatch !== null) {
    if (activeMatch === idx) {
      if (percentage > 60) return "bg-text-lightGreen";
      if (percentage > 25) return "bg-orange-300";
      return "bg-background-red";
    }

    if (percentage > 60) return "bg-background-green opacity-60";
    if (percentage > 25) return "bg-orange-300 opacity-60";
    return "bg-background-red opacity-60";
  }

  return probabilityBackground(percentage);
};
export default function MinCriteria() {
  const [activeMatch, setActiveMatch] = useState<number | null>(null);

  return (
    <>
      {matches.map((match, idx) => (
        <div
          key={idx}
          className={`flex items-center rounded-9 w-[56px] h-[108.75px] py-[37px] px-[13px] font-600 text-14 leading-[17.64px] ${handleActiveMatchBg(
            match.percentage,
            activeMatch,
            idx
          )} ${textColor(match.percentage)}`}
          onClick={() => setActiveMatch(idx)}
        >
          {match.percentage}%
        </div>
      ))}
    </>
  );
}
