import Action from "./action"
import CurrentPath from "./currentPath"
import DocumentSearch from "./documentSearch"
import { useGetDirectoryQuery, useGetProjectQuery } from "@/api/resources"
import ProjectContext from "@/components/Project/ProjectContext"

interface FolderProps {
  folderId?: string
}

export default function Folder({ folderId }: FolderProps) {
  const { data: folder } = useGetDirectoryQuery(folderId)
  const { data: project } = useGetProjectQuery(
    { project_id: folder?.project_id },
    { skip: !folder },
  )

  return (
    <ProjectContext.Provider value={project}>
      <CurrentPath folder={folder} />
      <Action folder={folder} />
      <DocumentSearch folder={folder} />
    </ProjectContext.Provider>
  )
}
