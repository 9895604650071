import { Icon } from "@/assets";

interface KeywordsProps {
  keywords: string[];
  parentClass: string;
  deleteKeyword?: (idx: number) => void;
  childClass?: string;
  iconRight?: boolean;
}
const defaultClose = () => console.log("deleteKeyword clicked");

export default function Keywords({
  keywords,
  deleteKeyword = defaultClose,
  parentClass,
  childClass,
  iconRight = true,
}: KeywordsProps) {
  return (
    <div className={`pb-10 overflow-y-scroll max-h-[400px] mb-4 flex gap-2 ${parentClass} flex-wrap`} style={{
      maskImage: "linear-gradient(to bottom, black calc(100% - 64px), transparent 100%)"
    }}>
      {keywords.map((keyword, _) => (
        <div
          key={_}
          className={
            childClass
              ? `${childClass}`
              : "rounded-6 bg-background-darkGrey text-text-darkGrey font-500 text-16 leading-4 flex gap-2 items-center w-fit p-2"
          }
        >
          {keyword}
          {iconRight && (
            <button onClick={() => deleteKeyword(_)}>
              <Icon name="Close" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
