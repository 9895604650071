"use client";
import { useParams } from "react-router-dom"

import { Folder } from "@/components";
import { ProjectProvider } from "@/context/projectContext";

export default function page() {
  const { id } = useParams()

  return (
    <ProjectProvider>
      <div className="w-full bg-border-gray" style={{ height: "1px" }}></div>

      <div className="py-6 wrapper flex flex-col gap-8">
        <Folder folderId={id} />
      </div>
    </ProjectProvider>
  );
}
