import { useView } from "@/context/ViewContext";
import DocumentHeader from "./documentHeader";
import Main from "./main";
import { SavedSearchSchema } from "@/api/resources/types";

export default function Left({ keywords, setKeywords, matches, activeMatch, setActiveMatch }) {
  const {splitView} = useView();
  // TODO: this should really be context
  const onSavedSearchSelected = (search: SavedSearchSchema) => {
    if (search) {
      setKeywords(search.keywords)
    } else {
      setKeywords([])
    }
  }

  return (
    <div
      className={`${
        splitView
          ? "p-4"
          : "pr-[72px] pl-[56px] pb-8 pt-8 w-1/2"
      } min-h-full flex flex-col overflow-y-scroll`}
    >
      {!splitView && <DocumentHeader onSavedSearchSelected={onSavedSearchSelected} />}
      <Main keywords={keywords} setKeywords={setKeywords} matches={matches} activeMatch={activeMatch} setActiveMatch={setActiveMatch} />
    </div>
  );
}
export { getColor } from "@/components/left/DocumentContext";