import Documents from "@/components/Documents";
import { criteriaData } from "@/data/criteria";
import { Dropdown } from "@/elements";
import { useProjectContext } from "@/components/Project/ProjectContext";
import { useGetProjectQuery } from "@/api/resources";

export default function SemanticTab() {
  const project = useProjectContext()

  // TODO: here use semantic search result instead
  const { data } = useGetProjectQuery(
    { project_id: project?.project_id },
  )

  return (
    <div>
      <Dropdown
        defaultValue={0}
        options={criteriaData}
        icon="BlueDownarrow"
        currentBtnClassName="bg-bgSecondary w-full rounded-12 py-[17px] px-4 flex justify-between items-center font-500 text-16 text-gray-500"
        optDivClassName="absolute top-12 left-0 bg-bgSecondary rounded-b-10 flex flex-col p-4 w-full z-10"
        optBtnClassName="block hover:bg-gray-200 hover:rounded-10 py-3 px-4 w-full text-left leading-6 font-400 text-16 text-gray-500"
      />

      <div className="flex flex-col gap-4 pt-10">
        <Documents matches={25} documents={data?.contents} />
      </div>
    </div>
  );
}
