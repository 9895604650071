import { createContext, ReactNode, useContext, useState } from "react";

type ViewContextType = {
  splitView: boolean;
  toggleView: () => void;
};

const ViewContext = createContext<ViewContextType | undefined>(undefined);

export const ViewProvider = ({ children }: { children: ReactNode }) => {
  const [splitView, setSplitView] = useState<boolean>(false);

  const toggleView = () => setSplitView((prev) => !prev);

  return (
    <ViewContext.Provider value={{ splitView, toggleView }}>
      {children}
    </ViewContext.Provider>
  );
};

export const useView = () => {
  const context = useContext(ViewContext);
  if (context === undefined) {
    throw new Error("useView must be used within a ViewProvider");
  }
  return context;
};
