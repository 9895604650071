import { useMemo, useState } from "react"

import KeywordsTab from "./keywordsTab"
import SemanticTab from "./semanticTab"
import { PopulatedDirectorySchema } from "@/api/resources"
import FilesystemView from "@/components/Project/project/FilesystemView"

type Tabs = "keywords" | "semantic"

interface DocumentSearchProps {
  folder?: PopulatedDirectorySchema
}

export default function DocumentSearch({ folder }: DocumentSearchProps) {
  const [activeTab, setActiveTab] = useState<Tabs>("keywords")
  const [keywords, setKeywords] = useState<string[]>([])

  const handleKeywords = (newKeywords: string[]) => setKeywords(newKeywords)

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab)
  }

  const documents = useMemo(() => {
    return folder?.contents.filter((x) => x.resource_type === "document") ?? []
  }, [folder])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <button
          onClick={() => handleTabClick("keywords")}
          className={`font-500 text-15 leading-5 border-b text-black ${
            activeTab === "keywords"
              ? " border-blue-200"
              : "border-transparent opacity-20"
          }`}
        >
          Keywords
        </button>
        <button
          onClick={() => handleTabClick("semantic")}
          className={`font-500 text-15 leading-5 border-b text-black ${
            activeTab === "semantic"
              ? " border-blue-200"
              : "border-transparent opacity-20"
          }`}
        >
          Semantic
        </button>
      </div>

      {activeTab === "keywords" && (
        <div>
          <KeywordsTab keywords={keywords} handleKeywords={handleKeywords} />
          {folder && (
            <FilesystemView
              folderId={folder.directory_id}
              data={folder.contents ?? []}
              isSearchingKeywords={keywords.length > 0}
            />
          )}
        </div>
      )}
      {activeTab === "semantic" && <SemanticTab folder={folder} />}
    </div>
  )
}
