"use client";
import { useState } from "react";

import { Icon } from "@/assets";
import MyLibrary from "@/components/left/myLibrary";
import { Modal } from "@/elements";

export default function Library({ onSelect }) {
  const [openLibrary, setOpenLibrary] = useState<boolean>(false);

  const onSelectOption = (x) => {
    onSelect(x)
    setOpenLibrary(false)
  }

  return (
    <>
      <Modal isOpen={openLibrary} setOpen={setOpenLibrary} title="Saved searches">
        <MyLibrary onSelect={onSelectOption} />
      </Modal>

      <button
        className="font-500 hover:bg-gray-100 rounded-5 p-1 flex items-center gap-[1.5px]"
        style={{ color: "#5E8AB6" }}
        onClick={() => setOpenLibrary(true)}
      >
        <Icon name="LibraryBlue" />
        Saved searches
      </button>
    </>
  );
}
