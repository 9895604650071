import { Icon, UserAvatar } from "@/assets"
import { useAppSelector } from "@/redux/hooks"
import { AuthState } from "@/redux/slices/auth"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { useForm } from "react-hook-form";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Tabs } from "@radix-ui/react-tabs";
import { TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetUserQuery } from "@/api/resources";
import UpdateAvatarModal from "../UpdateAvatarModal"
import { useMemo } from "react";

const profileFormSchema = z.object({
  username: z.string().min(2).max(50),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),  // todo
  password: z.string(),
  repeatPassword: z.string(),
})

interface ProfileModalProfile {
  onClose: () => void
}

function ChangeProfileData() {
  const auth = useAppSelector(state => state.auth)
  const { data: userInfo, isSuccess } = useGetUserQuery()

  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: auth.username,
      email: userInfo.email,
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
    }
  })
  return <Form {...form}>
    <div className="flex gap-4">
      <FormField name="firstName" control={form.control} render={({field}) => <FormItem className="flex-grow">
        <FormLabel>First name</FormLabel>
        <FormControl>
          <Input placeholder="First name" {...field} />
        </FormControl>
      </FormItem>}>
      </FormField>
      <FormField name="lastName" control={form.control} render={({field}) => <FormItem className="flex-grow">
        <FormLabel>Last name</FormLabel>
        <FormControl>
          <Input placeholder="Last name" {...field} />
        </FormControl>
      </FormItem>}>
      </FormField>
    </div>
    <FormField name="email" control={form.control} render={({field}) => <FormItem>
      <FormLabel>E-mail</FormLabel>
      <FormControl>
        <Input disabled placeholder="email" {...field} />
      </FormControl>
      <FormDescription>E-mail can't be changed.</FormDescription>
    </FormItem>}>
    </FormField>
    <FormField name="phone" control={form.control} render={({field}) => <FormItem>
      <FormLabel>Phone number</FormLabel>
      <FormControl>
        <Input placeholder="Enter phone number" {...field} />
      </FormControl>
    </FormItem>}>
    </FormField>
    <Button type="submit">Save changes</Button>
  </Form>
}

function ChangePassword() {
  const auth = useAppSelector(state => state.auth)
  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: auth.username,
      email: auth.email,
      firstName: auth.firstName,
      lastName: auth.lastName,
    }
  })
  return <Form {...form}>
    <h3 className="font-500">Change Password</h3>
    <FormField name="new-password" control={form.control} render={({field}) => <FormItem>
      <FormLabel>New Password</FormLabel>
      <FormControl>
        <Input placeholder="••••••••" type="password" autoComplete="new-password" {...field} />
      </FormControl>
    </FormItem>}>
    </FormField>
    <FormField name="repeat-password" control={form.control} render={({field}) => <FormItem>
      <FormLabel>Repeat Password</FormLabel>
      <FormControl>
        <Input placeholder="••••••••" type="password" autoComplete="off" {...field} />
      </FormControl>
    </FormItem>}>
    </FormField>
    <Button type="submit">Change password</Button>
  </Form>
}

function UpdatableProfilePicture () {
  const { data: user } = useGetUserQuery()
  const userImage = useMemo(() => user?.photo ? `data:image/jpeg;base64,${user.photo}` : UserAvatar, [user])
  return <div className="flex justify-center">
    <div className="flex justify-center items-center relative justify-self-center w-max">
      <img src={userImage} alt={"profile avatar"} />
      <UpdateAvatarModal trigger={
        <button className="absolute rounded-50 bg-blue-300 hover:bg-blue-50 w-10 h-10 flex justify-center items-center bottom-0 right-0">
          <Icon name="Camera" />
        </button>
      } />

    </div>
  </div>
}

export default function ProfileModalNew({ isOpen, setOpen }) {
  const auth = useAppSelector(state => state.auth)
  const form = useForm<z.infer<typeof profileFormSchema>>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: auth.username,
      email: auth.email,
      firstName: auth.firstName,
      lastName: auth.lastName,
    }
  })

  return <Dialog open={isOpen} onOpenChange={setOpen}>
    <DialogContent className="">
      <DialogHeader>
        <DialogTitle className="">Account Settings</DialogTitle>
      </DialogHeader>
      <Tabs defaultValue="account">
        <TabsList className="grid w-full grid-cols-2 mt-1">
          <TabsTrigger value="account">Profile</TabsTrigger>
          <TabsTrigger value="password">Password</TabsTrigger>
        </TabsList>
        <TabsContent value="account" className="w-full space-y-5 pt-4">
          <UpdatableProfilePicture />
          <ChangeProfileData />
        </TabsContent>
        <TabsContent value="password" className="w-full space-y-5 pt-4">
          <ChangePassword />
        </TabsContent>
      </Tabs>
    </DialogContent>
  </Dialog>
}

interface InputProps {
  placeholder: string
  type: string
  value?: string
  name: string
  required?: boolean
}

const InputOld = ({
  placeholder,
  type,
  value,
  name,
  required = false,
}: InputProps) => {
  return (
    <div className="flex flex-col gap-2">
      <label htmlFor={name} className="font-500 text-14 leading-4 text-black">
        {name}
      </label>
      <input
        required={required}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        className="rounded-12 p-4 border text-15 leading-5 placeholder:text-[#B6CADE] text-black"
        style={{ border: "1px solid #B6CADE" }}
      />
    </div>
  )
}
