import Library from "./library";

interface leftProps {
  children: React.ReactNode;
}

export default function SearchBar({ children, onSelect }: leftProps) {
  return (
    <div>
      <div className="flex gap-6 flex-col pt-16">
        <h1 className="text-black font-300 text-25 leading-10 tracking-[0.01em] text-center">
          What do you want to identify?
        </h1>
        {children}
      </div>
      <div className="flex justify-end py-4 w-full">
        <Library onSelect={onSelect} />
      </div>
      <div className="relative w-full p-[11px] my-4">
        <div className="absolute left-0 w-full border-t border-gray-300 top-1/2"></div>
        <div className="absolute left-1/2 transform -translate-x-1/2 bg-white px-[35px] leading-[21.6px] text-18 font-500 text-gray-200 top-0">
          or
        </div>
      </div>
    </div>
  );
}
