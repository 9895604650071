import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

export interface DropdownValue {
  name: string
  id: string
  color: string
}

interface SelectProps {
  values: DropdownValue[]
  selectedValue: DropdownValue
  setSelectedValue: any
}

const NewSelect = ({
  values,
  selectedValue,
  setSelectedValue,
}: SelectProps) => {
  return (
    <>
      <Select
        value={selectedValue?.name ?? ""}
        onValueChange={(newValue) => {
          const valueObj = values.find((x) => x.name === newValue)
          setSelectedValue(valueObj)
        }}
      >
        <SelectTrigger
          className="w-[180px]"
          style={{ color: selectedValue?.color ?? "" }}
        >
          <SelectValue placeholder="Change status" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {/*<SelectLabel>Change status</SelectLabel>*/}
            {values.map((x,_) => (
              <SelectItem
              key={_}
                value={x.name}
                style={{ color: x.color, marginTop: "4px" }}
              >
                {x.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}

export default NewSelect
