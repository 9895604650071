import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useLocation, useParams } from "react-router"
import { Link } from "react-router-dom"
import axios from "axios"
import unidecode from "unidecode"

import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks"
import { Left, Right } from "@/components"
import { htmlFile as fakeHtmlFile } from "@/mock/data/tempfile"
import DocumentContext, {
  SemanticMatch,
} from "@/components/left/DocumentContext"
import {
  useGetDocumentMetadataQuery,
  useGetSemanticSearchQuery,
} from "@/api/resources"
import AppPage from "@/AppPage"
import { Icon } from "@/assets"
import { accumulateText, useParser } from "@/components/right/readOnlyEditor/DocumentParser";
import { getIntersection } from "@/utils/keywords";

const PROBA_THRESHOLD = 0.05

function isSameBin (a, b) {
  if (a >= 0.6 && b >= 0.6) return true;
  if (a >= 0.25 && a < 0.6 && b >= 0.25 && b < 0.6) return true;
  if (a < 0.25 && b < 0.25) return true;
  return false;
}

function sortFn(a, b) {
  // originally: (a, b) => b.value - a.value
  if (isSameBin(b.value, a.value)) {
    return a.index - b.index
  } else {
    return b.value - a.value
  }
}

export default function page() {
  const { id } = useParams()
  const [keywords, setKeywords] = useState<string[]>([])
  const [matches, setMatches] = useState<string[]>([])
  const [activeMatch, setActiveMatch] = useState<number | null>(null)
  const [semanticSearchClass, setSemanticSearchClass] = useState<string | null>(
    null,
  )

  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const [htmlFile, setHtmlFile] = useState<string | null>(null)

  const location = useLocation()
  const { data: documentMetadata, isLoading } = useGetDocumentMetadataQuery(id)

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, [location.pathname])

  useEffect(() => {
    if (id !== undefined) {
      // setHtmlFile()
      const options = {
        headers: {
          Accept: "text/html",
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios
        .get(`${API_URL}/documents/${id}`, options)
        .then((resp) => {
          if (resp.status == 200) {
            setHtmlFile(resp.data)
          } else {
            setHtmlFile(null)
          }
        })
        .catch((err) => {
          // TODO
          // setHtmlFile(fakeHtmlFile)
          throw err
        })
    } else {
      setHtmlFile(fakeHtmlFile)
    }
  }, [id])

  const setKeywordsAndClearMatches = useCallback(
    (newKeywords) => {
      // Called when keywords are updated to also clear matches
      setMatches([])
      setActiveMatch(null)
      setKeywords(newKeywords)
    },
    [setKeywords, setMatches],
  )

  const { data: semanticSearchData } = useGetSemanticSearchQuery(
    { document_id: id, class: null },
    { skip: !id },
  )
  const { searchableElements } = useParser({ htmlFile, keywords, activeMatch })

  const semanticMatchesHtml: SemanticMatch[] = useMemo(() => {
    if (!semanticSearchData || !searchableElements || !semanticSearchClass || documentMetadata?.file_type === "pdf") {
      return []
    }
    const data = semanticSearchData["predictions"][semanticSearchClass]
    if (data.length !== searchableElements.length) {
      console.log(
        `ERROR: data.length != searchableElements.length (${data.length}, ${searchableElements.length})`,
      )
      return []
    }
    const matches_ = []
    for (let i = 0; i < data.length; i++) {
      if (data[i] < PROBA_THRESHOLD) {
        continue
      }
      const elem = searchableElements[i]
      matches_.push({ index: elem.idx, matchIndex: i, text: elem.text, value: data[i] })
    }
    return matches_.sort(sortFn)
  }, [semanticSearchData, searchableElements, semanticSearchClass])

  const semanticMatchesPdf: SemanticMatch[] = useMemo(() => {
    if (!semanticSearchData || !semanticSearchClass || documentMetadata?.file_type !== "pdf") {
      return []
    }
    // TODO: crashes if "document is being processed"
    const data = semanticSearchData["predictions"][semanticSearchClass]
    const matches_ = []
    for (let i = 0; i < data.length; i++) {
      if (data[i] < PROBA_THRESHOLD) {
        continue
      }
      const matchText = semanticSearchData["text_items"][i]["text"]
      matches_.push({ index: i, matchIndex: i, text: matchText, value: data[i] })
    }
    return matches_.sort(sortFn)
  }, [semanticSearchData, semanticSearchClass])

  // TODO: refactor the following ...

  const keywordsPrepro = useMemo(() => {
    return keywords.map((keyword) => {
      return keyword.replaceAll(/\s+/g, " ").trim().toLowerCase()
    }).filter((x) => x.length > 0)
  }, [keywords])

  const keywordMatchesPdf = useMemo(() => {
    if (!semanticSearchData || !keywords || documentMetadata?.file_type !== "pdf") {
      return []
    }
    const texts = semanticSearchData["text_items"]
    const matches_ = []
    for (let i = 0; i < texts.length; i++) {
      const text = texts[i]["text"]
      const { hasIntersection } = getIntersection(text, keywordsPrepro)
      if (hasIntersection) {
        // TODO: think about what index is which
        matches_.push({ index: i, matchIndex: matches_.length, text, value: -1 })
      }
    }
    return matches_.sort(sortFn)
  }, [keywords, semanticSearchData])

  const semanticMatches = documentMetadata?.file_type === "pdf" ? (semanticSearchClass ? semanticMatchesPdf : keywordMatchesPdf) : semanticMatchesHtml
  useEffect(() => {
    if (documentMetadata?.file_type === "pdf" && !semanticSearchClass) {
      setMatches(keywordMatchesPdf.map((m) => m.text))
    }
  }, [semanticMatches])

  // ... until here

  if (!isLoading && (!id || !documentMetadata)) {
    return (
      <AppPage>
        <div className="flex flex-col flex-grow gap-5 justify-center items-center">
          <span className="text-20">Document not found.</span>
          <Link
            to="/"
            className="flex items-center gap-2 font-500 text-15 text-gray-400 hover:text-gray-700"
          >
            <Icon name="BackArrow" />
            Back To Main Page
          </Link>
        </div>
      </AppPage>
    )
  }

  return (
    <DocumentContext.Provider
      value={{
        documentId: id,
        document: documentMetadata,
        htmlFile,
        semanticMatches,
        semanticSearchClass,
        setSemanticSearchClass,
        activeMatch,
        setActiveMatch,
        semanticSearchData,
      }}
    >
      <div className="flex w-full h-full overflow-hidden justify-between">
        <Left
          keywords={keywords}
          setKeywords={setKeywordsAndClearMatches}
          matches={matches}
          activeMatch={activeMatch}
          setActiveMatch={setActiveMatch}
        />
        <Right
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      </div>
    </DocumentContext.Provider>
  )
}
