import { criteriaData } from "@/data/criteria";
import { Dropdown } from "@/elements";

interface DropdownProps {
  chosenCriteria: number;
}

export default function CriteriaDropdown({ chosenCriteria }: DropdownProps) {
  return (
    <Dropdown
      defaultValue={chosenCriteria}
      options={criteriaData}
      icon="BlueDownarrow"
      currentBtnClassName="bg-bgSecondary w-full rounded-12 p-4 flex justify-between items-center font-500 text-16 text-gray-500"
      optDivClassName="absolute top-12 left-0 bg-bgSecondary rounded-b-10 flex flex-col p-4 w-full z-10"
      optBtnClassName="block hover:bg-gray-200 hover:rounded-10 py-3 px-4 w-full text-left leading-6 font-400 text-16 text-gray-500"
    />
  );
}
