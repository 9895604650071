import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { allProjects, recentProjects } from "@/mock/data/projects";

const initialState = {
  allProjects,
  recentProjects,
}


export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    dummy: (state) => {}
  },
})

export const { dummy } = projectsSlice.actions
export default projectsSlice.reducer
