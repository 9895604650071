"use client"
import { useEffect, useState } from "react"

import { Icon } from "@/assets"
import { ProjectDocument } from "@/types/document"
import Sort from "../sort"
import Document from "./document"
import GroupDocuments from "./groupDocuments"
import { ResourceSchema } from "@/api/resources"
import FilesHeader from "@/components/Project/project/FilesHeader"
import { Button } from "@/components/ui/button"

interface FilteredDocumentsProps {
  documents?: ResourceSchema[]
  title?: string
  matches?: number
}

export default function Documents({
  title,
  documents,
  matches,
}: FilteredDocumentsProps) {
  const [openDocumentMore, setOpenDocumentMore] = useState<number | null>(null)
  const [openSort, setOpenSort] = useState<boolean>(false)
  const [filteredDocuments, setFilteredDocuments] =
    useState<ProjectDocument[]>()
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [selected, setSelected] = useState<number[]>([])

  const toggleSelected = (id: number) => {
    if (selected.includes(id))
      setSelected((prev) => prev.filter((prevId) => prevId !== id))
    else setSelected([...selected, id])
  }

  const handleToggleMore = (index: number) => {
    setOpenDocumentMore((prevIndex) => (prevIndex === index ? null : index))
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    if (checked) {
      setSelectedStatuses((prev) => [...prev, value])
    } else {
      setSelectedStatuses((prev) => prev.filter((option) => option !== value))
    }
  }

  const filterDocuments = () => {
    if (selectedStatuses.length > 0) {
      const filtered = documents?.filter((doc) =>
        selectedStatuses.includes(doc.status),
      )
      setFilteredDocuments(filtered)
    } else {
      setFilteredDocuments(documents)
    }
  }

  const handleDocGrouping = () => {
    console.log("selected documents:", selected)
    setSelected([])
  }

  useEffect(() => {
    setFilteredDocuments(documents)
    filterDocuments()
  }, [selectedStatuses, documents])

  return (
    <div className="flex flex-col gap-4">
      <FilesHeader
        title={title}
        matches={matches}
        buttons={
          <>
            <Button
              variant="ghost"
              className="flex gap-1.5"
              onClick={() => setOpenSort(!openSort)}
            >
              <Icon name="Sort" />
              <span className="text-text-neutral text-16 font-500">
                Sort By
              </span>
            </Button>
            {openSort && (
              <Sort
                selectedStatuses={selectedStatuses}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
          </>
        }
      />

      <div className="grid gap-4 grid-cols-4">
        {filteredDocuments?.map((document, _) => (
          <Document
            key={_}
            toggleSelected={toggleSelected}
            selected={selected}
            document={document}
            isOpen={openDocumentMore === _}
            onToggle={() => handleToggleMore(_)}
          />
        ))}
      </div>

      {selected.length > 0 && (
        <GroupDocuments handleDocGrouping={handleDocGrouping} />
      )}
    </div>
  )
}
