import { Icon } from "@/assets"
import Status, { DropdownValue } from "@/elements/status"
import { STATUS_DROPDOWN_VALUES } from "@/types/status"
import { useState } from "react"

interface GroupDocumentsProps {
  handleDocGrouping: () => void
}

export default function GroupDocuments({
  handleDocGrouping,
}: GroupDocumentsProps) {
  const [status, setStatus] = useState<DropdownValue>(STATUS_DROPDOWN_VALUES[2])

  return (
    <div className="fixed bottom-0 right-0 w-full bg-white flex items-center justify-center gap-4 py-6 border-t border-background-darkBlue">
      <div className="px-3.5 font-500 text-15 leading-5 text-gray-500 flex gap-1.5 justify-between items-center">
        Status:
        <Status
          values={STATUS_DROPDOWN_VALUES}
          selectedValue={status}
          setSelectedValue={setStatus}
        />
      </div>
      <div className="h-4" style={{ borderLeft: "1px solid #B6CADE" }}></div>

      <button
        onClick={handleDocGrouping}
        className="flex items-center gap-1.5 text-14 leading-6 font-500"
        style={{ color: "#4975A1" }}
      >
        <Icon name="Folder" />
        Create folder with selection
      </button>
      <div className="h-4" style={{ borderLeft: "1px solid #B6CADE" }}></div>

      <button className="px-3.5 flex items-center gap-1.5 font-500 text-background-lightRed">
        <Icon name="Delete" />
        Delete
      </button>
    </div>
  )
}
