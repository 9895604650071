import { useEffect, useMemo, useState } from "react";

import { Icon } from "@/assets"
import ProgressBar from "@/components/folders/progressBar"
import { uploadingDocs } from "@/data/uploadingDocs"
import { uploadingDocsType } from "@/types/uploadingDocs"
import { Simulate } from "react-dom/test-utils"
import progress = Simulate.progress
import { UploadingItem } from "@/UploadContext";

interface DocumentsUploadProps {
  chosenFiles: UploadingItem[]
  removePopup: () => void
}

export default function DocumentsUpload({
  chosenFiles,
  // status,
  removePopup,
}: DocumentsUploadProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  // const [files, setFiles] = useState<uploadingDocsType[]>([])//uploadingDocs)

  const toggleDropdown = () => setIsOpen((prev) => !prev)

  const renderDocumentStatus = (doc: any) => {
    if (doc.status === "done") {
      return (<div className="py-1 px-2 rounded text-14 leading-4 text-text-green bg-background-green w-fit">
        Uploaded
      </div>)
    } else if (doc.status === "pending") {
      return (<p className="text-14 leading-4 text-gray-400">Pending</p>)
    } else if (doc.status === "error") {
      return (<p className="py-1 px-2 rounded text-14 leading-4 w-fit text-red-500 bg-background-red">Error</p>)
    }

    return (
      <div className="text-12 text-gray-400 flex gap-4">
        {doc.percentage} % <span>{0} sec left</span>
      </div>
    )
  }

  const files = useMemo(() => {
    if (chosenFiles) {
      return chosenFiles.map((f, ix) => ({
          id: ix,
          name: f.file.name,
          size: `${f.file.size}mb`,
          status: f.status,
          percentage: f.progress * 100,
          time: "?",
      }))
    }
    return []
  }, [chosenFiles])

  const arePending = files.some((x) => x.status === "in_progress" || x.status === "pending")

  return (
    <div
      className="border border-border-gray rounded-9 p-6 gap-4 bg-bgSecondary flex flex-col fixed z-10 right-2 bottom-2 overflow-y-scroll overflow-x-hidden"
      style={{ width: "364px", maxHeight: "369px" }}
    >
      <button
        onClick={toggleDropdown}
        className="flex justify-between items-center"
      >
        <h3 className="text-16 leading-5">Documents Uploading</h3>
        <Icon
          name="BlueDownarrow"
          className={`transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>
      { !arePending &&
        <div className="flex items-center justify-between">
          <span className="text-black/80">All uploads have finished.</span>
        <button className="ml-auto" onClick={(e) => {
          removePopup()
        }}>
          <Icon name="Close" strokeColor="red" />
        </button>
        </div>
      }

      {isOpen && (
        <div className="flex flex-col gap-2 transition-opacity duration-500 ease-out opacity-0 animate-fade-in">
          {files.map((doc, _) => (
            <div
              key={_}
              className="flex flex-col gap-3 bg-white rounded-12 p-4 border border-border-gray"
            >
              <div className="grid grid-cols-[auto_1fr_auto] gap-4">
                <Icon name="DocumentSm" />

                <div className=" flex flex-col gap-2 overflow-hidden">
                  <p className="text-14 leading-4 truncate">{doc.name}</p>
                  {renderDocumentStatus(doc)}
                </div>

                {/*{doc.time > 0 && (*/}
                {/*  <button onClick={() => removeUploadingDocs(doc.id)}>*/}
                {/*    <Icon name="Delete" />*/}
                {/*  </button>*/}
                {/*)}*/}
              </div>

              {doc.status === "in_progress" && <ProgressBar percentage={doc.percentage} />}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
