import { ProjectDocument } from "@/types/document";
import { StatusEnum } from "@/types/status";
import { DocumentSchema, ResourceSchema } from "@/api/resources";

// TODO: DocumentSchema X ResourceSchema
// TODO: remove ProjectDocument completely

export const recentDocuments: ResourceSchema[] = [
  {
    document_id: "1",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Non-Disclosure",
    // mentions: 15,
    status: StatusEnum.done,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "2",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "X Company Law",
    // mentions: 14,
    status: StatusEnum.untouchable,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "3",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Non-Disclosure 2",
    // mentions: 3,
    status: StatusEnum.done,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "4",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Judgement",
    // mentions: 15,
    status: StatusEnum.inProgress,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "5",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Non-Disclosure",
    // mentions: 5,
    status: StatusEnum.done,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "6",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "X Company Law",
    // mentions: 11,
    status: StatusEnum.inReview,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "7",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Non-Disclosure 2",
    // mentions: 9,
    status: StatusEnum.done,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "8",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Judgement",
    // mentions: 4,
    status: StatusEnum.inReview,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "9",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Non-Disclosure",
    // mentions: 21,
    status: StatusEnum.done,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
  {
    document_id: "10",
    project_id: "b5d1c1a4-2b5d-4e3b-8b0d-0a1d1b4d9d1d",
    display_name: "Judgement",
    // mentions: 22,
    status: StatusEnum.inProgress,
    // fileType: "pdf",
    created_at: "2021-07-01T00:00:00",
    updated_at: "2021-07-01T00:00:00",
    resource_type: "document",
  },
];
