interface ProgressBarProps {
  percentage: number;
}

export default function ProgressBar({ percentage }: ProgressBarProps) {
  return (
    <div
      className={`w-full bg-gray-200 h-1 mt-1.5 `}
      style={{ borderRadius: "1px", background: "#F0F4F8" }}
    >
      <div
        className={`h-full ${
          percentage === 100 ? "bg-text-green" : "bg-blue-200"
        }`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}
