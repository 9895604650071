import { useEffect, useMemo, useState } from "react";

import { Modal } from "@/elements";
import DocumentsUpload from "./documentsUpload";
import ProjectHeader from "./ProjectHeader";
import ShareModal from "./ProjectHeader/shareModal";
import {
  useGetProjectQuery,
} from "@/api/resources";
import ProjectsSearch from "@/components/Project/project/main/ProjectsSearch";
import { ProjectSchema } from "@/api/resources/types";
import ProjectContext, { useOnUploadFileChange } from "@/components/Project/ProjectContext";
import { UploadFile } from "@/components/UploadFile";
import { Icon } from "@/assets";

interface ProjectProps {
  project: ProjectSchema;
}

export default function Project({ project }: ProjectProps) {
  // TODO: we probably don't need this anymore?
  // const { data: documents } = useGetProjectDocumentsQuery(project.project_id)
  const { data: projectData } = useGetProjectQuery(
    { project_id: project.project_id },
  )

  const [openDocUploading, setOpenDocUploading] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const {handleFileChange, fileUpload, chosenFiles, setChosenFiles} = useOnUploadFileChange()

  const toggleDropdown = () => setOpenDocUploading((prev) => !prev);
  const toggleShare = () => setOpenShare((prev) => !prev);

  useEffect(() => {
    // if chosen files have changed, close the upload dropdown
    setOpenDocUploading(false)
  }, [chosenFiles])

  // TODO: we should have a loading state to avoid showing EmptyProject
  return (
    <ProjectContext.Provider value={project}>
      <ProjectHeader project={project} toggleDropdown={toggleDropdown} toggleShare={toggleShare} />

      {project && (
        <div className="py-6 wrapper">
          <ProjectsSearch project={project} />
        </div>
      )}

      <Modal cls="max-w-xl" title="Upload Documents" isOpen={openDocUploading} setOpen={setOpenDocUploading}>
        {/* TODO: take the title and header style from UploadModal! */}
        <div className="flex justify-between items-center">
          <h3 className="font-400 text-22 leading-7">Upload Documents</h3>
          <button
            onClick={toggleDropdown}
            className="flex gap-1.5 text-text-neutral text-16 font-500"
          >
            <Icon name={"Close24"} />
          </button>
        </div>
        <UploadFile handleFileChange={handleFileChange} dashed />
      </Modal>

      <Modal isOpen={openShare} setOpen={setOpenShare} cls="max-w-xl">
        <ShareModal projectId={project.project_id} toggleShare={toggleShare} />
      </Modal>
    </ProjectContext.Provider>
  );
}
