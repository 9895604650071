import * as React from "react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu"
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Button } from "@/components/ui/button";

interface Props {
  isOpen: boolean
  setOpen: (x: boolean) => void
  items: {
    label: string
    icon: JSX.Element
    onClick: (e) => void
  }[]
  trigger: JSX.Element
}

export const DROPDOWN_ICON_STYLE = "h-[20px] mr-1"

export default function ActionDropdown({ isOpen, setOpen, items, trigger }: Props) {

  return (
    <DropdownMenu open={isOpen} onOpenChange={setOpen}>
      {trigger ? (
        <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
      ) : <DropdownMenuTrigger asChild>
        <Button variant="outline">Open</Button>
      </DropdownMenuTrigger>}

      <DropdownMenuContent className="py-2" align="end">
        {items.map((item, ix) => (
          <DropdownMenuItem key={ix} className="mb-1" onClick={item.onClick}>
            {item.icon}
            {item.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
