import React, { createContext } from "react";
import { DocumentSchema } from "@/api/resources";

export interface SemanticMatch {
  index: number // node index (not very nice)
  text: string // original text
  value: number // "probability"
}

interface DocumentPageState {
  documentId: string
  document: DocumentSchema | null
  htmlFile: string | null
  semanticMatches: SemanticMatch[]
  semanticSearchClass: string | null
  setSemanticSearchClass: (x: string | null) => void
  // shared between keywords and semantic search
  activeMatch: number | null
  setActiveMatch: (x: number | null) => void
}

export function getColor(value, isActive) {
  if (value > 0.6) {
    return isActive ? "#C3ECC4" : "#EBF9EB";
  }
  if (value > 0.25) {
    return isActive ? "#F9E6DB" : "#F9F6EB";
  }
  return isActive ? "#FDC0C0" : "#FDF4F4";
}

const DocumentContext = createContext<DocumentPageState|null>(null)

export const useDocumentContext = () => {
  const ctx = React.useContext(DocumentContext)
  if (!ctx) throw new Error("Document state can't be null in useDocumentContext")
  return ctx
}

export default DocumentContext