import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icon } from "@/assets"
import { CreateOrRenameFolderDialog } from "@/components/Project/project/CreateOrRenameFolderDialog";
import { ResourceSchema } from "@/api/resources";
import { useState } from "react";
import { DeleteFolderDialog } from "@/components/Project/project/DeleteFolderDialog";

interface Props {
  folder: ResourceSchema
}

export default function FolderDropdown({ folder }: Props) {
  const ICON_STYLE = "h-[20px] mr-1"
  const [isRenameModal, setRenameModal] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="py-2">
        <DropdownMenuItem className="mb-1" onClick={() => {setRenameModal(true)}}>
          <Icon name="Edit" className={ICON_STYLE} />
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => {setDeleteModal(true)}}>
          <Icon name="Delete" className={ICON_STYLE} />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
      {/* this is not very efficient*/}
      <CreateOrRenameFolderDialog projectId={folder.project_id} folderId={folder.directory_id} initialName={folder.display_name} action="rename" open={isRenameModal} onOpenChange={setRenameModal} />
      <DeleteFolderDialog directoryId={folder.directory_id} open={isDeleteModal} onOpenChange={setDeleteModal} />
    </DropdownMenu>
  )
}
