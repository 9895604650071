import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks";
import { UploadFile } from "@/components/UploadFile";

export default function UpdateAvatarModal({ trigger }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const accessToken = useAppSelector((state) => state.auth.accessToken)

  async function uploadPicture(imageData: FormData) {
    // TODO: use correct URL etc.
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    const resp = await axios.post(
      `${API_URL}/me/photo`,
      imageData,
      options,
    )
    // TODO: catch errors
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length === 1) {
      setIsUploading(true)
      const file = files.item(0)
      const formData = new FormData()
      formData.append("payload", file)
      uploadPicture(formData).finally(() => {
        setIsUploading(false)
        setIsOpen(false)
        // TODO: report errors
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Update profile picture</DialogTitle>
          <DialogDescription>
            You can update your profile picture here. Select a picture from your
            computer or drag and drop it below.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <UploadFile
            itemDesc="a picture"
            handleFileChange={handleFileChange}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
