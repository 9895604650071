import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Icon } from "@/assets"
import { ReloadIcon } from "@radix-ui/react-icons"
import {
  DocumentSchema,
  useCreateDirectoryMutation,
  useCreateProjectMutation,
  useRenameDirectoryMutation,
  useRenameDocumentMutation,
  useRenameProjectMutation,
} from "@/api/resources"

const createDocumentSchema = z.object({
  display_name: z.string().min(2).max(255),
})

export interface Params {
  document: DocumentSchema
  open: boolean
  onOpenChange: (boolean) => void
  showButton?: boolean
}

export const RenameDocumentDialog = ({
  document,
  open,
  onOpenChange,
  showButton,
}: Params) => {
  const [renameDocument, { isLoading, isSuccess, isError }] =
    useRenameDocumentMutation()

  const form = useForm<z.infer<typeof createDocumentSchema>>({
    resolver: zodResolver(createDocumentSchema),
    defaultValues: {
      display_name: document.display_name,
    },
  })
  const renderForm = ({ field }) => {
    return (
      <FormItem>
        <FormLabel>Folder name</FormLabel>
        <FormControl>
          <Input
            type="text"
            placeholder="Enter name of the document"
            {...field}
          />
        </FormControl>
        <FormDescription>
          The document name should have between 2 and 255 characters.
        </FormDescription>
      </FormItem>
    )
  }

  function onSubmit(values: z.infer<typeof createDocumentSchema>) {
    console.log(`RENAMING DOCUMENT TO ${values.display_name}`)
    renameDocument({
      document_id: document.document_id,
      display_name: values.display_name,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      onOpenChange(false)
      form.reset()
    } else if (isError) {
      // TODO: show error toast
    }
  }, [isSuccess, isError])

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        {showButton && (
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              className="flex items-center text-blue-200 font-500 text-16 gap-1.5"
            >
              <Icon name="Plus" />{" "}
              <span className="text-blue-200 text-15">Create Folder</span>
            </Button>
          </DialogTrigger>
        )}
        <DialogContent>
          <DialogHeader>
            <h3 className="text-22 text-black text-center">Rename document</h3>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="display_name"
                render={renderForm}
              />
              <Button
                variant="secondary"
                onClick={() => {
                  form.reset()
                  onOpenChange(false)
                }}
              >
                Cancel
              </Button>
              <Button className="ml-2" type="submit">
                {isLoading ? (
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  ""
                )}
                Rename
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
