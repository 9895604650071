import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Icon } from "@/assets"
import Status, { DropdownValue } from "@/elements/status"
import { useState } from "react"
import { STATUS_DROPDOWN_VALUES, StatusEnum } from "@/types/status"
import { DeleteProjectDialog } from "@/components/allProjects/DeleteProjectDialog"
import { ProjectSchema } from "@/api/resources/types";
import { CreateOrRenameProjectDialog } from "@/components/allProjects/CreateOrRenameProjectDialog";
import { Modal } from "@/elements";
import ShareModal from "@/components/Project/project/ProjectHeader/shareModal";

interface Props {
  project: ProjectSchema
}

export default function ProjectDropdown({ project }: Props) {
  const ICON_STYLE = "h-[20px] mr-1"

  const initialStatus = project.status ?? StatusEnum.untouchable
  const [selectedStatus, setSelectedStatus] = useState<DropdownValue>(
    STATUS_DROPDOWN_VALUES.find((x) => x.name === initialStatus),
  )

  const [isRenameModal, setRenameModal] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [isShareModal, setShareModal] = useState(false)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Icon name="More" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Status</DropdownMenuLabel>
        <Status
          values={STATUS_DROPDOWN_VALUES}
          selectedValue={selectedStatus}
          setSelectedValue={setSelectedStatus}
        />
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => {
          setRenameModal(true)
        }}>
          <Icon name="Edit" className={ICON_STYLE} />
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => {
          setShareModal(true)
        }}>
          <Icon name="Share" className={ICON_STYLE} />
          Share
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={isDeleteModal}
          onClick={() => {
            setDeleteModal(true)
          }}
        >
          <Icon name="Delete" className={ICON_STYLE} />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>

      <CreateOrRenameProjectDialog
        projectId={project.project_id}
        initialName={project.display_name}
        action="rename"
        open={isRenameModal}
        onOpenChange={setRenameModal}
      />
      <DeleteProjectDialog
        projectId={project.project_id}
        open={isDeleteModal}
        onOpenChange={setDeleteModal}
      />
      <Modal isOpen={isShareModal} setOpen={setShareModal} cls="max-w-xl">
        <ShareModal projectId={project.project_id} toggleShare={() => {setShareModal(!isShareModal)}} />
      </Modal>

    </DropdownMenu>
  )
}
