import { useView } from "@/context/ViewContext"
import ReadOnlyEditor from "./readOnlyEditor"
import ReadWriteEditor from "./readWriteEditor"
import { getColor, useDocumentContext } from "@/components/left/DocumentContext";
import { DocumentInitParameters } from "pdfjs-dist/types/src/display/api"
import {
  PdfHighlighter,
  PdfHighlighterUtils,
  TextHighlight,
  useHighlightContainerContext
} from "react-pdf-highlighter-extended";
import { PdfLoader, AreaHighlight } from "react-pdf-highlighter-extended"
import { API_URL } from "@/config"
import { useAppSelector } from "@/redux/hooks";
import { useEffect, useMemo, useRef } from "react";

const PdfView = () => {
  const { documentId, semanticSearchData: data, semanticMatches, activeMatch, keywords } = useDocumentContext()
  const authToken = useAppSelector(state => state.auth.accessToken)
  const pdfDocument: Partial<DocumentInitParameters> = useMemo(() => ({
    url: `${API_URL}/documents/${documentId}?foo`,
    httpHeaders: {
      Authorization: `Bearer ${authToken}`,
      Accept: "application/octet-stream",
    },
    // password: "PDF_PASSWORD_HERE",
  }), [documentId, authToken])

  /** Refs for PdfHighlighter utilities
   * These contain numerous helpful functions, such as scrollToHighlight,
   * getCurrentSelection, setTip, and many more
   */
  const highlighterUtilsRef = useRef<PdfHighlighterUtils>();

  const highlights = useMemo(() => {
    return semanticMatches?.map((match, i) => {
      const { page: pageNumber, xyxy: [x1, y1, x2, y2] } = data["text_items"][match.index]
      const pageBox = data["page_bboxes"][pageNumber]
      const pageWidth = pageBox[2] - pageBox[0]
      const pageHeight = pageBox[3] - pageBox[1]

      return {
        id: `${match.matchIndex}`,
        type: "area",
        content: undefined,
        match: match,
        position: {
          rects: [],
          boundingRect: {
            pageNumber: pageNumber + 1,
            width: pageWidth,
            height: pageHeight,
            x1,
            x2,
            y1: y1, //pageHeight - y2,
            y2: y2, //pageHeight - y1,
          }
        }
      }
    }) ?? []
  }, [semanticMatches])


  useEffect(() => {
    if (activeMatch !== null) {
      const hi = highlights.find((x) => x.id == activeMatch)
      // FIXME: unfortunately this doesn't work for pages which were not yet loaded!!!
      if (hi) {
        highlighterUtilsRef.current?.scrollToHighlight(hi)
      } else {
        console.warn("Highlight item hasn't been loaded yet!!!")
      }
    }

  }, [activeMatch])

  const MakeHighlight = () => {
    const {
      highlight, // The highlight being rendred
      viewportToScaled, // Convert a highlight position to platform agnostic coords (useful for saving edits)
      screenshot, // Screenshot a bounding rectangle
      isScrolledTo, // Whether the highlight has been auto-scrolled to
      highlightBindings, // Whether the highlight has been auto-scrolled to
    } = useHighlightContainerContext();
    // active match won't work here somehow since this is an internal viewer obj
    let bgColor
    if (highlight.match.value == -1) {
      bgColor = "#3076EE55"
    } else {
      bgColor = getColor(highlight.match.value, isScrolledTo)
    }
    return <div className={`dontscrollmanually-semantic-match-${highlight.id}`}><AreaHighlight
      isScrolledTo={isScrolledTo}
      highlight={highlight}
      style={{ backgroundColor: bgColor }}
    /></div>
  }


  const { splitView } = useView()
  return (
    <div className={`py-0 mr-0 max-h-screen w-1/2 overflow-y-scroll`}>
      <div
        className={` ${
          splitView ? "p-8" : "px-0 pb-0"
        } bg-bgSecondary rounded-10 flex flex-col`}
      >
        {/*{!splitView && <ViewControl toggleView={toggleView} />}*/}
        {/*<PlaceholderText />*/}
        {/* CSS attribute is enabled by next.config.mjs -> styledComponents: true */}
        <div className="bg-white">
          <PdfLoader document={pdfDocument}>
            {(pdfDocument) => {
              return (
                <PdfHighlighter
                  style={{ border: '1px solid red', width: "50%" }}
                  enableAreaSelection={(event) => event.altKey}
                  pdfDocument={pdfDocument}
                  utilsRef={(_pdfHighlighterUtils) => {
                    highlighterUtilsRef.current = _pdfHighlighterUtils;
                  }}
                  // selectionTip={<ExpandableTip />} // Component will render as a tip upon any selection
                  highlights={highlights}
                >
                  <MakeHighlight />
                  {/* User-defined HighlightContainer component goes here */}
                </PdfHighlighter>
              )
            }}
          </PdfLoader>
          {/*{ isClient ? <div className="doc-preview" css={cssStyle}>{parsed}</div> : "" }*/}
        </div>
      </div>
    </div>
  )
}

export default function Right({ keywords, setMatches, activeMatch }) {
  const { splitView } = useView()
  const { document, htmlFile } = useDocumentContext()

  return (
    <>
      {document?.file_type === "pdf" && <PdfView />}
      {htmlFile && (
        <ReadOnlyEditor
          keywords={keywords}
          setMatches={setMatches}
          activeMatch={activeMatch}
        />
      )}
      {splitView && <ReadWriteEditor />}
    </>
  )
}
