import RecentDocuments from "./recentDocuments"
import RecentFolders from "./recentFolders"
import Top from "./header"
import Plus from "./plus"

export default function Dashboard() {
  return (
    <>
      <Top />
      <div className="py-8 flex flex-col gap-12 wrapper">
        <RecentFolders />
        <RecentDocuments />
      </div>
      <div className="relative">
        <Plus />
      </div>
    </>
  )
}
