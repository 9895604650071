import { useState } from "react";

import { Icon } from "@/assets";
import { ProjectStatus } from "@/elements";
import { Project } from "@/types/project";
import {Link} from "react-router-dom";
import More from "./more";
import ProjectDropdown from "@/components/projects/ProjectDropdown";
import { ProjectSchema } from "@/api/resources/types";

interface ProjectsProps {
  projects: ProjectSchema[];
  className?: string;
}

export default function Projects({ projects, className }: ProjectsProps) {

  return (
    <div className="grid gap-4 grid-cols-4">
      {projects.map((project, _) => (
        <div
          key={_}
          className={`flex flex-col gap-2 justify-between rounded-14 p-6 pr-4 bg-white w-full ${className}`}
        >
          <div className="relative flex justify-between items-center">
            <Icon name="Case" />

            <ProjectDropdown project={project} />
          </div>

          <Link
            className="text-16 font-500 text-gray-500"
            to={`/projects/${project.project_id}`}
          >
            {project.display_name}
          </Link>

          <ProjectStatus status={project.status} />
        </div>
      ))}
    </div>
  );
}
