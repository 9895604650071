"use client";

import { Icon } from "@/assets";
import { IconNameEnum } from "@/types/icons";
import { useEffect, useRef, useState } from "react";

interface DropdownProps {
  defaultValue: number;
  options: any[];
  icon: keyof typeof IconNameEnum;
  parentClassName?: string;
  currentBtnClassName?: string;
  optDivClassName?: string;
  optBtnClassName?: string;
  status?: boolean;
}

export default function Dropdown({
  defaultValue,
  options,
  icon,
  parentClassName,
  currentBtnClassName,
  optBtnClassName,
  optDivClassName,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className={`relative ${parentClassName}`} ref={dropDownRef}>
      <button
        type="button"
        className={`${currentBtnClassName}`}
        onClick={toggleDropdown}
      >
        {selectedOption || options[defaultValue]?.name || options[defaultValue]}
        <Icon
          name={icon || "BlueDownarrow"}
          className={`transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div
          className={`absolute left-0 flex flex-col min-w-full z-10 ${optDivClassName} transition-opacity duration-500 ease-out opacity-0 animate-fade-in`}
          style={{ top: "calc(100% + 4px)" }}
        >
          {options.map((option, idx) => (
            <button
              key={idx}
              onClick={() => handleOptionClick(option)}
              className={`${
                selectedOption === option && "hidden"
              } ${optBtnClassName}`}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
