"use client";

import { useState } from "react";
import KeywordsTab from "../keywordsTab";
import SemanticTab from "../semanticTab";

type Tabs = "keywords" | "semantic";

export default function ProjectsSearch({ project }) {
  const [activeTab, setActiveTab] = useState<Tabs>("keywords");

  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <button
          onClick={() => handleTabClick("keywords")}
          className={`font-500 text-15 leading-5 border-b text-black ${
            activeTab === "keywords"
              ? " border-blue-200"
              : "border-transparent opacity-20"
          }`}
        >
          Keywords
        </button>
        <button
          onClick={() => handleTabClick("semantic")}
          className={`font-500 text-15 leading-5 border-b text-black ${
            activeTab === "semantic"
              ? " border-blue-200"
              : "border-transparent opacity-20"
          }`}
        >
          Semantic
        </button>
      </div>

      {activeTab === "keywords" && <KeywordsTab project={project} />}
      {activeTab === "semantic" && <SemanticTab />}
    </div>
  );
}
