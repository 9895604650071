import { UploadFile } from "@/components/UploadFile"
import { useOnUploadFileChange } from "@/components/Project/ProjectContext"

interface EmptyProjectProps {
  folderId?: string | undefined
  // handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// TODO: perhaps we can also remove this component now!
export default function EmptyProject({ folderId }: EmptyProjectProps) {
  const { handleFileChange } = useOnUploadFileChange(folderId)
  return (
    <div className="h-full flex flex-col gap-8">
      <UploadFile dashed handleFileChange={handleFileChange} />
    </div>
  )
}
