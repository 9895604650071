import { Icon } from "@/assets"
import DocumentDropdown from "@/components/Documents/document/DocumentDropdown"
import { ProjectStatus } from "@/elements"
import { Link } from "react-router-dom"
import { DocumentSchema } from "@/api/resources";

interface DocumentProps {
  document: DocumentSchema
  isOpen: boolean
  onToggle: () => void
  // mentions?: number
  toggleSelected: (id: number) => void
  selected: number[]
}
export default function Document({
  document,
  isOpen,
  onToggle,
  // mentions,
  toggleSelected,
  selected,
}: DocumentProps) {
  const mentions = document.search_hits?.length ?? 0
  return (
    <div
      onClick={() => toggleSelected(document.document_id)}
      className={`overflow-hidden flex flex-col gap-2 justify-between rounded-14 p-6 pr-4 border w-full ${selected.includes(document.document_id) ? "border-blue-200" : "border-border-gray"}`}
    >
      <div
        className={`flex items-center ${
          mentions ? "justify-between" : "justify-end"
        }`}
      >
        <div
          className={`rounded-5 px-1.5 text-12 leading-6 text-text-neutral ${
            mentions ? "block" : "hidden"
          }`}
          style={{ background: "#F0F4F8" }}
        >
          {mentions} Mentions
        </div>
        {/* TODO: n_mentions is confusing because right now it's n_keywords */}
        <DocumentDropdown document={document} />
      </div>

      <Link className="flex flex-col gap-2 items-center" style={{ minWidth: 0 }} to={`/document/${document.document_id}`}>
        <Icon name="Document" />
          <p className="text-15 leading-4 text-black" style={{ minWidth: 0, textOverflow: "ellipsis" }}>{document.display_name}</p>
          <p className="text-14 leading-4 text-gray opacity-40 pt-2 pb-4">
            {document?.file_type?.toUpperCase() ?? "??"}
          </p>
          <ProjectStatus status={document?.status ?? ""} />
      </Link>
    </div>
  )
}
