import Folder from "@/components/folders";
import { recentProjects } from "@/mock/data/projects";
import { useState } from "react";

// TODO: implement this
export default function RecentFolders() {
  const folders = recentProjects[0].contents.filter((x) => x.resource_type === "directory")

  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-500 text-18 leading-6 text-black">Recent Folders</h3>
      <div className="grid gap-4 grid-cols-4">
        {folders.map((folder, idx) => (
          <Folder
            key={idx}
            link={`/folders/${folder.directory_id}`}
            folder={folder}
          />
        ))}
      </div>
    </div>
  );
}
