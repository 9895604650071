import React, { createContext, useEffect, useState } from "react"

import { ProjectSchema } from "@/api/resources/types"
import { useUploadFile } from "@/api/upload_file"
import DocumentsUpload from "@/components/Project/project/documentsUpload";

export interface UploadState {
  chosenFiles: UploadingItem[]|null
  setChosenFiles: (x: UploadingItem[]|null) => void
}

export interface UploadingItem {
  file: File
  progress: number
  status: "in_progress" | "pending" | "done" | "error"
}

const UploadContext = createContext<UploadState | null>(null)

export const useUploadContext = () => {
  const ctx = React.useContext(UploadContext)
  if (!ctx) throw new Error("UploadContext is null!")
  return ctx
}

export const UploadProvider = ({ children }) => {
  const [chosenFiles, setChosenFiles] = useState<UploadingItem[]|null>(null);
  const isUploadInProgress = chosenFiles?.some((x) => x.status === "in_progress" || x.status === "pending")

  // if upload is in progress or pending, warn user before closing browser
  useEffect(() => {
    if (isUploadInProgress) {
      window.onbeforeunload = (event) => {
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [isUploadInProgress])
  return (
    <UploadContext.Provider value={{chosenFiles, setChosenFiles}}>
      {children}
      {chosenFiles?.length > 0 && <DocumentsUpload chosenFiles={chosenFiles!} removePopup={() => {
        setChosenFiles(null)
      }} />}
    </UploadContext.Provider>
  )
}

export default UploadContext
